import React, { ReactElement } from 'react';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';
import Pill from 'components/Pill';

import styles from './RadioPill.module.css';

interface Props extends FieldRenderProps<string | number, HTMLInputElement> {
  className?: string;
  block?: boolean;
  label?: string | ReactElement;
}

/**
 * Custom RadioButton for use with FinalForm.
 * if FinalForm is not used, props for the RadioButton has to be passed through the "input" prop.
 *
 * Example usage:
 * <Field name={""} type="radio" render={Radio}/>
 *
 *
 * @param className "composed className"
 * @param label label
 * @param disable disable the radio
 */

const RadioPill: React.FC<Props> = ({ className, input, label, ...props }) => {
  return (
    <Pill
      size="large"
      className={cx(styles.root, className)}
      color={input.checked ? 'green' : 'subdued'}
      component="label"
      {...props}
    >
      {label}
      <input {...input} className={styles.hidden} />
    </Pill>
  );
};

export default RadioPill;
