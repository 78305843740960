import React from 'react';
import { DayPickerInputProps, DayPickerProps } from 'react-day-picker';
import { Field, Form } from 'react-final-form';
import { FormSpy } from 'react-final-form';
import cx from 'classnames';
import Block from 'components/Block';
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import DayPicker from 'components/Form/Daypicker';
import FieldSet from 'components/Form/FieldSet';
import RadioPill from 'components/Form/RadioPill';
import Select from 'components/Form/Select';
import { useApi } from 'contexts/ApiContext';
import { Config as FormConfig } from 'final-form';
import moment from 'moment';
import { useRouter } from 'next/router';
import { ParsedUrlQueryInput } from 'querystring';
import { DataResult } from 'utils/data-sources';

import ROUTES from '../../../../routes';
import { Service } from '../../../../types/Clinic';
import { City, TimeslotsQuery } from '../../../../types/Timeslots';
import NODES from '../../../djedi-nodes/timeslots';
import ArrowSVG from '../../../icons/arrow_forward.svg';
import { searchTimeslotsVariant } from '../../../utils/enums';
import { citiesDataSource } from '../data-sources/citiesDataSource';
import { servicesDataSource } from '../data-sources/servicesDataSource';
import { useMomentCalendarDateSpec } from '../hooks/useMomentCalendarDateSpec';
import timeslotsFormStyles from '../styles/TimeslotsForm.module.css';
import styles from './SearchTimeslotsForm.module.css';

export type SearchTimeslotsFormProps = Partial<FormConfig<TimeslotsQuery, TimeslotsQuery>> & {
  cities?: DataResult<City[]>;
  services?: DataResult<Service[]>;
  onSearched?(): void;
  className?: string;
};

/**
 * Re-usable form for searching for timeslots. Navigates to the `TIMESLOTS` route when submitted by default.
 * Currently only applies to `Market.NO`.
 */
const SearchTimeslotsForm: React.FC<SearchTimeslotsFormProps> = props => {
  const {
    cities: initialCities,
    services: initialServices,
    onSearched,
    className,
    ...otherProps
  } = props;

  const api = useApi();

  const { data: cities } = citiesDataSource.use({ initial: initialCities }, api);
  const { data: services } = servicesDataSource.use({ initial: initialServices }, api);

  const defaultCityOption = {
    name: 'Velg by',
    services: services.map(service => service.slug), // All services
  };

  const today = React.useMemo(() => new Date(), []);
  const initialValues = React.useMemo((): TimeslotsQuery => {
    const query: TimeslotsQuery = {
      service: 'nipt',
      date_from: today,
      city: defaultCityOption.name,
      ...props.initialValues,
    };

    if (query.daytime && query.evening && query.weekend) {
      delete query.daytime;
      delete query.evening;
      delete query.weekend;
    }

    return query;
  }, [defaultCityOption.name, props.initialValues, today]);

  const validate = (values: TimeslotsQuery) => {
    const errors: Partial<Record<keyof TimeslotsQuery, string>> = {};
    if (values.city === defaultCityOption.name) {
      errors.city = NODES.INVALID_DEFAULT_CITY.props.children;
    }
    return errors;
  };

  const router = useRouter();

  const onSubmit: SearchTimeslotsFormProps['onSubmit'] = React.useCallback(
    values => {
      /*
        We keep the query string as small as possible, so we omit all values that will not
        make any difference from the default arguments (date: today, variants: all).
      */
      const query: ParsedUrlQueryInput = {};

      const formattedDateFrom = moment(values['date_from']).format('YYYY-MM-DD');
      const formattedInitialDateFrom = moment(initialValues.date_from).format('YYYY-MM-DD');

      if (values['date_from'] && formattedDateFrom !== formattedInitialDateFrom) {
        query[ROUTES.TIMESLOTS.queryKeys.DATE_FROM] = formattedDateFrom;
      }

      if (values['service']) {
        query[ROUTES.TIMESLOTS.queryKeys.SERVICE] = values['service'];
      }

      if (values['city']) {
        query[ROUTES.TIMESLOTS.queryKeys.CITY] = values['city'];
      }

      const variants: number[] = [];
      if (values.daytime) {
        variants.push(searchTimeslotsVariant.daytime);
      }
      if (values.evening) {
        variants.push(searchTimeslotsVariant.evening);
      }
      if (values.weekend) {
        variants.push(searchTimeslotsVariant.weekend);
      }
      if (variants.length > 0 && variants.length < 3) {
        query[ROUTES.TIMESLOTS.queryKeys.VARIANT] = variants;
      }

      const searchPromise = router.push({
        pathname: ROUTES.TIMESLOTS.href,
        query,
      });

      if (typeof onSearched === 'function') {
        searchPromise.then(onSearched);
      }
    },
    [router, initialValues.date_from, onSearched],
  );

  const calendarDateSpec = useMomentCalendarDateSpec();
  const dayPickerProps = React.useMemo((): {
    placeholder?: string;
    dayPickerInputProps?: DayPickerInputProps;
    dayPickerProps?: DayPickerProps;
  } => {
    return {
      dayPickerProps: {
        disabledDays: { before: today },
        selectedDays: initialValues.date_from,
      },
      dayPickerInputProps: {
        formatDate: date => {
          const formattedDate = moment(date).calendar(null, calendarDateSpec);
          return formattedDate[0].toUpperCase().concat(formattedDate.slice(1));
        },
      },
    };
  }, [initialValues.date_from, today, calendarDateSpec]);

  const classNames = {
    dayPicker: {
      inputWrapper: timeslotsFormStyles.inputWrapper,
      icon: timeslotsFormStyles.inputIcon,
    },
    select: {
      input: timeslotsFormStyles.input,
      chevron: timeslotsFormStyles.inputIcon,
    },
    checkbox: {
      checkboxWrapper: timeslotsFormStyles.checkboxWrapper,
      emptyCheckbox: timeslotsFormStyles.emptyCheckbox,
      checkedCheckbox: timeslotsFormStyles.checkedCheckbox,
    },
  };

  return (
    <Form
      {...otherProps}
      initialValues={initialValues}
      onSubmit={props.onSubmit || onSubmit}
      validate={validate}
    >
      {formState => (
        <form className={className} onSubmit={formState.handleSubmit}>
          <Block className={timeslotsFormStyles.fields}>
            <FieldSet className={styles.fieldset}>
              <FieldSet className={cx(styles.fieldset, styles.flex)}>
                {services.map(service => (
                  <Field
                    key={service.slug}
                    name="service"
                    type="radio"
                    label={service.name}
                    value={service.slug}
                    render={RadioPill}
                    className={styles.servicePill}
                  />
                ))}
              </FieldSet>
              <Field name="city" render={Select} classNames={classNames.select} type="select">
                {[defaultCityOption, ...cities].map(city => (
                  <option
                    key={city.name}
                    value={city.name}
                    disabled={
                      // if a service is selected, only show cities that have that service
                      formState.values.service != null &&
                      !city.services.includes(formState.values.service)
                    }
                  >
                    {city.name}
                  </option>
                ))}
              </Field>
              <Field
                name="date_from"
                render={DayPicker}
                // type="text"
                classNames={classNames.dayPicker}
                {...dayPickerProps}
                dayPickerProps={{
                  ...dayPickerProps.dayPickerProps,
                  selectedDays: formState.values.date_from,
                }}
              />
            </FieldSet>
            <div className={timeslotsFormStyles.checkboxes}>
              <Field
                name="daytime"
                render={Checkbox}
                label={NODES.DAYTIME}
                classNames={classNames.checkbox}
                type="checkbox"
              />
              <Field
                name="evening"
                render={Checkbox}
                label={NODES.EVENING_TIME}
                classNames={classNames.checkbox}
                type="checkbox"
              />
              <Field
                name="weekend"
                render={Checkbox}
                label={NODES.WEEKEND}
                classNames={classNames.checkbox}
                type="checkbox"
              />
            </div>
          </Block>
          <FormSpy<TimeslotsQuery> subscription={{ values: true }}>
            {({ values }) => {
              if (values.service && values.city && cities) {
                const selectedCity = cities.find(city => city.name === values.city);
                if (selectedCity && !selectedCity.services.includes(values.service)) {
                  formState.form.change('city', defaultCityOption.name);
                }
              }
              return null;
            }}
          </FormSpy>

          <Button block type="submit" icon={ArrowSVG} disabled={formState.invalid}>
            {NODES.SUBMIT_SEARCH}
          </Button>
        </form>
      )}
    </Form>
  );
};

export default SearchTimeslotsForm;
